/* jshint ignore:start */
/*
 * Ajax Fetch content
 *
 * Use to either fetch & replace or fetch and append/preprend content.
 * EX:
 *
 * $('.link-class').on('click',function(e){
 *       e.preventDefault();
 *       window._fetch.replaceContent($(this).attr('href'), '.container-to-load-content','.content-to-load');
 *       window._fetch.appendContent ($(this).attr('href'), '.container-to-load-content','.content-to-load');
 *       window._fetch.prependContent($(this).attr('href'), '.container-to-load-content','.content-to-load');
 *  })
 */

(function ($) {

    // Create job object
    var _fetch = {
        'container': '',
        'callback': '',
        'content' : '',
        'results' : '',
        'url' : '',
        'ajax' : '',

        // Function used to prepend content
        callbackContent: function(url, callback ){
            _fetch.callback = callback;
            _fetch.handleRequest( url, "body", "", 'callback' );
        },
        // Function used to replace content
        replaceContent: function(url, container, content  ){
            _fetch.handleRequest( url,container, content, 'html' );
        },
        // Function used to append content
        appendContent: function(url, container, content  ){
            _fetch.handleRequest( url, container, content, 'append' );
        },
        // Function used to prepend content
        prependContent: function(url, container, content ){
            _fetch.handleRequest(url, container, content, 'prepend' );
        },
        // Function takes results and handles them according to action (replace/append/prepend)
        handleContent: function(){
            if(_fetch.action === "callback" ){
                _fetch.callback( _fetch.results );
            }else{
                console.log('amending results');
                _fetch.container[_fetch.action]( _fetch.results.find(_fetch.content).html() );
            }
        }, //
        // Universal function for handling fetch requests. Given action by calling function
        handleRequest: function(url, container, content,  action){
            // Sets object variables when called
            _fetch.container = $(container);
            if(content.length > 1){
                _fetch.content = content;
            }else{
                _fetch.content = container;
            }
            _fetch.action = action;
            _fetch.url = url;
            // fetches page
            _fetch.fetchPage();
        },
        // Sets the url in the location bar to match the new content
        setURL: function (url) {
            history.pushState({}, '', url);
        },
        // Fetch results page
        fetchPage: function(){
            // Non ajax page reload
            _fetch.container.addClass('j-loading');
            _fetch.container.trigger('ajaxLoadBegin');
            // ajax request a page
            _fetch.ajax = jQuery.ajax({
                dataType: 'text',
                url: _fetch.url,
                success: function (rawhtml) {
                    // get results
                    _fetch.results = $(rawhtml);
                    _fetch.handleContent();
                    _fetch.container.removeClass('j-loading');
                    _fetch.container.trigger('ajaxLoadComplete');
                },
                error: function(){
                    _fetch.container.trigger('ajaxLoadFail');
                }
            });
        }
    };
    // Attach Toolbox to window //
    window._fetch = _fetch;
})(jQuery); // Fully reference jQuery after this point.




