/* jshint ignore:start */
jQuery( document ).ready( function ($) {

	$('.j-loadmore').on('click', '.j-loadmore--button', function (e) {
		// Setup _this variable and prevent default behaviour
		var _this = $(this);
		e.preventDefault();
		
		// Exit if in progress class is added
		if (_this.hasClass('in-progress')) return;

		// Add in progress class to prevent multiple clicks
		_this.addClass('in-progress');

		// Get the container element
		var container = _this.parents('.j-loadmore');

		// Get a list of classes for the object to use to find it in the results
		var containerClasses = '.' + container.attr('class');
		containerClasses = containerClasses.split(' ').join('.');

		// Get the page URL to fetch
		var url = $(this).attr('href');

		// Fetch the URL and replace/append the content
		window._fetch.callbackContent(url, function (result) {
			// Get results object
			var results = $(result);
			// Remove in progress class
			_this.removeClass('in-progress');
			// Add the posts from the results to the posts on the page
			container.find('.j-loadmore--posts').append(results.find(containerClasses).find('.j-loadmore--posts').html());
			// Replace the pagination on the page with the pagination on the fetched page
			container.find('.j-loadmore--pagination').html(results.find(containerClasses).find('.j-loadmore--pagination').html());
		});
	});

});